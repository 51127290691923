import React from 'react'
import * as XLSX from 'xlsx';



const Downloadtoexcel = ({data, fileName}) => {

      // // Extract the rows from the data object
      const rows = data.rows;

      const newObject = { 
        totalAmount : data.totalAmount,
        netamount : data.netamount,        
      };

      const newArray = [...rows, newObject];




      // // Create an array of objects for the rows
      //   const rowObjects = rows.map((row, index) => ({
      //     "Sno": row.sno,
      //     'Product Name': row.productname,
      //     'Product Price': row.productprice,
      //     "Quantity": row.quantity,
      //     "Amount": row.amount,
      //   }));


        // rowObjects.forEach((rowObj) => {
        //   Object.assign(rowObj, {
        //     // 'Invoice Number': data.invoiceno,
        //     // 'Date of Purchase': data.dateofpurchase,
        //     // 'Payment Status': data.paymentstatus,
        //     // 'Lot Number': data.lotnumber,
        //     // "cardnumber" : data.cardnumber,
        //      "discount" : data.discount,
        //     // "holdername" : data.holdername,
        //     "netamount" : data.netamount,
        //     // "paymentmethod" : data.paymentmethod,
        //     // "producttype" : data.producttype,
        //      "totalAmount" : data.totalAmount,
        //     // "vendorname" : data.vendorname,
        //     // "vendoremail" : data.vendoremail,
        //     // "vendornumber" : data.vendornumber,
        //     // "vendoraddress" : data.vendoraddress
            
        //   });
        // });

        // Combine the row objects into a single array including the main data
      // const dataArray = [data, ...rowObjects];

    const handleDownloadXL = () => {

        const worksheet = XLSX.utils.json_to_sheet(newArray);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
      };
  return (
<button className="dropdown-item" onClick={handleDownloadXL}>Export To Excel</button>  )
}

export default Downloadtoexcel